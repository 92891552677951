<template>


  <div class="home-container">
  
	<div class="topBar__topBar__iyVpg">
	    <div class="topBar__nav_logo__e4mAT" >
	       <img alt="Header Logo" 
			:src="
			  this.$store.getters.getBaseInfo.ico !== undefined
			    ? this.$store.getters.getBaseInfo.ico
			    : '/img/null.png'
			"
		   >
	    </div>
	    <div class="topBar__container__DvWco" @click="toService()">
	        <div class="topBar__tool__l7k57">
	            <img src="/img/home/kf.png" class="topBar__message_icon__MB9ze" >
		    </div>
	    </div>
	</div>
	
	<div class="banner">
		<swiper class="banner_swiper" :options="bannerSwiperOption">
			<swiper-slide v-for="(v,key) in banners" :key="key">
			   <van-image class="banner_img" round :src="v.url">
				 <template v-slot:loading>
				   <van-loading type="circular"/>
				 </template>
			   </van-image>
			</swiper-slide>
			
		</swiper>
		<div class="swiper-pagination" :slot="pagination"></div>
    </div>
		
	<div class="notice-bar">
		<van-notice-bar
			class="notice-swipe"
			left-icon="/img/home/home_notice.png"
			background="#ffffff"
			color="#7e5678"
			speed="20"
			:text="this.notice"
		/>
		<div class="linear-gradient"></div>
	</div>	
	
	<div class="userCard__userCardContainer__yIDir">
	  <div class="userCard__userCard__QDTPg">
	    <div class="userCard__userInfomation__zZs7y userCard__noDomain__goe7Z">
	      <div class="userCard__notLoginBox__vaz0Z">
	        <span data-click-sound="true" v-show="show == 0">
	          <h3>{{$t("setting.log_reg")}}</h3>
	        </span>
			
			<div v-show="show !== 0">
				<div class="userCard__nameAndGrade__hCIFY">
					<div>
					  <p>{{this.userInfo.username}}</p>
					</div>
				</div>
			  <ul class="userCard__balance__BF1QQ">
			    <!--li class="userCard__yyy__e08U7"></li-->
			    <li class="userCard__money__k_Fab">{{this.userInfo.money}}</li>
			 
			  </ul>
			</div>
			
	        <div>
				
			</div>
	      </div>
	    </div>
	    <ul class="userCard__walletContent__i8o3v">
	      <li class="userCard__walletItem__XFZPV"  @click="toService()" >
	        <img alt="" src="/img/home/feature_moneysave.png" class="userCard__icon__frlnp">
	        <p>{{$t("my.recharge")}}</p>
	      </li>
	      <li class="userCard__walletItem__XFZPV" @click="doWithdrawal()">
	        <img alt="" src="/img/home/feature_moneytransfer.png" class="userCard__icon__frlnp">
	        <p>{{$t("my.withdraw")}}</p>
	      </li>
	      <li class="userCard__walletItem__XFZPV"   @click="$router.push({path:'/Personalreport'});">
	        <img alt="" src="/img/home/account_treasury.png" class="userCard__icon__frlnp">
	        <p>{{$t("my.account_detail")}}</p>
	      </li>
	      <li class="userCard__walletItem__XFZPV" @click="exit()">
	        <img alt="" src="/img/home/feature_vip.png" class="userCard__icon__frlnp">
	        <p>{{$t("my.my_statement")}}</p>
	      </li>
	    </ul>
	  
	  </div>
	</div>
		
	
	
	<div class="venueSection__venueSectionContainer__y5i6w" >
		<div class="show-area" v-show="showlist !== 0">
			<ul class="style__cateTab__tq8fn" >
					  
				
					
				
			<van-sidebar @change="onChange" v-model="activeKey">
				     <van-sidebar-item :title="'/img/home/qb.png'" />
				    
						 <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key"  :title="v.ico"> </van-sidebar-item>
				   </van-sidebar>
			
			 </ul>
			 <div class="venueSection__venueWrap__oHfbu" id="contentVenue">
					  
					  
					
					   <ul class="venueSection__venuesList__HW7SP" id="venueContainer" style="padding-bottom: 1.3rem;">
					  	    
					  		 <li @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key"   class="venueSection__venuesItem__yAxzE" data-click-sound="true">
					  	        <div  class="__vens" ></div>
					  	        <div class="index__venueCardItem__ARiYA">
					  	          <div class="index__venueCard__jhtMq index__venueCardStyle__WJXiD" >
					  	            <img :src="v.ico" class="index__bgImg__LzUmU">
					  	            <div class="index__venueName__Uucy0">
					  	              <i>{{v.name}}</i>
					  	            </div>
					  	          
					  	          </div>
					  			</div>
					  	      </li>
			
					  	    </ul>
						 
			 </div>
				
		</div>
	
		<div class="hide-area" v-show="showlist == 0">
			{{$t("setting.more_service")}}
		</div>
	</div>
		
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData:[],
	    lotteryitem: [{},{},{},{}],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      isLoading: false,
	   activeKey: 0,
	   userInfo:{
	   },
	   show:0,
	   showlist:0,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
         pagination: {
             el: '.swiper-pagination',
           },
        speed:800,
        autoplay:true,
       
      }
    };
  },
  methods:{
	  onChange(index) {
	    this.$http({
	      method: 'get',
	      data:{class:index},
	      url: 'lottery_list'
	    }).then(res=>{
	      this.gameitem = res.data;
	  	 
	    })
	  },
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("刷新成功");
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
		
        this.getNotice(this.basicData);//获取公告
        console.log(this.basicData);
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data

        
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(data){
      this.banners = data.banners;
    },
    getLotteryItem(){
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res=>{
        this.lotteryitem = res.data;
		
      })
    },
	toService(){
	  if(this.$store.getters.getBaseInfo.iskefu == 1){
	    this.$router.push("ServiceOnline");
	  }else {
	    this.$toast.fail(this.$t("setting.forbid"));
	  }
	},
	doWithdrawal(){
	  this.$http({
	    method: 'get',
	    url: 'user_get_bank'
	  }).then(res=>{
	      if(res.data.is_bank){
	        this.$router.push("withdraw");
	      }else {
	        this.$router.push("Setbank");
	        this.$toast.fail(this.$t("setting.set_bank"));
	      }
	  })
	},
	exit(){
	  this.$toast(this.$t("my.finish_task"));
	},
	getUserInfo(){
	  this.$http({
	    method: 'get',
	    url: 'user_info'
	  }).then(res=>{
	    if(res.code === 200){
	        this.userInfo = res.data;
			this.show=1;
			this.showlist=1;
	        if(this.userInfo.status !== 1){	         
	          localStorage.clear()
	          this.$router.push({path:'/Login'})
	        }
	    }else if(res.code ===401){
	      this.$toast(res.msg);
	    }
	  })
	},
	  
	
  },
  mounted () {
	document.querySelector("body").setAttribute("style", "background-color:#eaf4ff");
  },
  created() {
    this.getBasicConfig();
	 this.getLotteryItem();
	 
	if(localStorage.getItem('token')){
		
	    this.getUserInfo();
	}else {
	  this.userInfo.username = this.$t("setting.log_reg");
	
	} 
	 
	 
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;


	a, abbr, acronym, address, applet, big, blockquote, body, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, object, ol, p, pre, q, s, samp, small, span, strike, strong, sub, sup, tt, ul, var {
		    font-family: DinPro,dinpro,PingFangSC-Regular,sans-serif;
		    font-size: .24rem;
		    margin: 0;
		    outline: 0;
		    padding: 0;
		}
		img {
		    border-style: none;
		}
		body{
			height: 100%;
			    background: #eaf4ff;
		}
		body, div, fieldset, form, h1, h2, h3, h4, h5, h6, html, p {
		    -webkit-text-size-adjust: 100%;
		}
		.topBar__topBar__iyVpg {
		    align-items: center;
		    background-color: #fff;
		    display: flex;
		    justify-content: space-between;

		    padding: 2vw 4vw;
		    position: sticky;
		    top: 0;
		    z-index: 980;
		}
		.topBar__nav_logo__e4mAT {
		    margin-right: 0.2rem;
		}
		.topBar__nav_logo__e4mAT img {
		    display: block;
		       height: 9.933vw;
		       width: 25.933vw;
		}


.topBar__topBar__iyVpg .topBar__container__DvWco {
    align-items: center;
    display: flex;
    justify-content: center;
}

.topBar__topBar__iyVpg .topBar__tool__l7k57 .topBar__message_icon__MB9ze {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 5vw;
}

.banner{

      padding: 0px 4vw;
	  position: relative;
	      background: #fff;
	  
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
.banner .swiper-container {
	position: relative;
}
.banner .swiper-pagination{
	position: absolute;

	bottom: 1vh;
	left: 0;
	z-index: 9;
	    width: 100%;
}
::v-deep .banner .swiper-pagination .swiper-pagination-bullet{
	    background-color: #6882b5;
	    border-radius: 0.3vw;
	    width: 3vw;
	    opacity: 20%;
	      height: 0.5vh;
	   margin: 0 0.5vw;
}

::v-deep .banner .swiper-pagination .swiper-pagination-bullet-active{
	background-color: #ffffff;
	width: 3vw;
	opacity: 70%;
}

::v-deep .banner_img{
      border-radius: 3.5vw;
  width: 100%;
  height: 100%;
}


.notice-bar{
	padding: 2vw 4vw 0;
	background-color: #fff;
}
::v-deep .notice-bar img{
	width: 4.2vw;
	    height: 4.2vh;
	    margin-right: 1vw;
}
::v-deep .van-notice-bar__content{
	font-size: 3.4vw;
	color: #000000;
}


.userCard__userCardContainer__yIDir {
	background-color: #fff;
    border-radius: 0 0 4vw 4vw;
        box-shadow: 0 1.333vw 2.667vw 0 rgb(53 120 246 / 10%);
      
        padding: 1vw 4vw 2vw;
        position: relative;
        z-index: 1;
    
}
.userCard__userCard__QDTPg {
    align-items: center;
    display: flex;
 
    justify-content: space-between;
    position: relative;
}

.userCard__userInfomation__zZs7y {
    display: flex;
    justify-content: space-between;
    max-width: 40vw;
    overflow: hidden;
    padding: 2px 0;
    width: 100%;
}


.userCard__walletContent__i8o3v {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.userCard__notLoginBox__vaz0Z h3 {
    color: #51587D;
    font-family: PingFangSC;
    font-size: 3.8vw;
    letter-spacing: -0.35px;
    line-height: 3vh;
}
.userCard__notLoginBox__vaz0Z p {
    color: #6c7ba8;
	font-size: 3vw;
	margin-top: 0.5vw;

}
.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV{
	   text-align: center;
}
.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV:not(:last-of-type) {
    margin-right: 0.2vw;
	    padding-right: 3vw;
}
.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV .userCard__icon__frlnp {
        height: 11vw;
        position: relative;
        width: 11vw;
}
.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV p {
    color: #51587D;
        display: block;
        font-size: 3.5vw;
        margin-top: -0.8vh;
 
        text-align: center;
        position: relative;
}

.userCard__nameAndGrade__hCIFY {
    align-items: center;
    display: flex;
}
.userCard__nameAndGrade__hCIFY div {
    max-width:40vw;
    overflow-x: auto;
}
.userCard__nameAndGrade__hCIFY div p {
    color: #51587D;
    font-family: DINPro;
    font-size: 3.4vw;
    margin-bottom: 0;
}
.userCard__balance__BF1QQ {
    align-items: center;
    color: #000;
    display: flex;
    height: 3vh;
    text-align: left;
}
.userCard__balance__BF1QQ .userCard__yyy__e08U7 {
    font-family: AkrobatBold;
    font-size: 3.6vw;
    margin-right: 1vw;
}

.userCard__balance__BF1QQ .userCard__money__k_Fab {
    font-family: AkrobatBold;
    font-size: 4vw;
    font-weight: 700;
    letter-spacing: normal;
    line-height: normal;
}







.venueSection__venueSectionContainer__y5i6w  .show-area{
    background-color: #eaf4ff;
    display: flex;
}

.venueSection__venueSectionContainer__y5i6w  .hide-area{
        margin: 0 auto;
        text-align: center;
        line-height: 140px;
        color: #000000;
        font-family: PingFangSC;
        font-size: 4.8vw;
}



.style__cateTab__tq8fn {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin:4vw 4vw 1vh 4vw;
    position: relative;
}
.venueSection__venueWrap__oHfbu {
    flex: 1;

    overflow: hidden;
    position: relative;
    width: 100%;
}
.style__cateTab__tq8fn>li {
    margin-bottom: 1vh;
    position: relative;
}

.style__cateTab__tq8fn .style__tabItem__lgbSy {
    background-repeat: no-repeat;
   background-repeat: no-repeat;
       background-size: contain;
       display: flex;
       filter: drop-shadow(0 0.3vw 0.3vw rgba(121, 152, 252, 0.35));
       flex-direction: column;
       position: relative;
       will-change: filter;
	background: #fff;
	text-align: center;
	border-radius: 2vw;
	    padding: 0 1.2vw;
}

.style__cateTab__tq8fn .style__tabItem__lgbSy>img {
           width: 8.5vw;
           height: 8.5vw;
		margin: 0 auto;
}
.style__cateTab__tq8fn .style__tabItem__lgbSy span {
   font-size: 2.5vw;
       font-weight: 400;
       text-align: center;
       padding-bottom: 1.5vw;
	   color: #545a78;
       white-space: nowrap;
       margin-top: -0.5vw;
       width: 10vw;
}

::v-deep .style__cateTab__tq8fn .van-sidebar-item{
	margin-bottom: 1vh;
	border-radius: 2vw;
	
}



::v-deep .style__cateTab__tq8fn img{
	width: 100%;
}




::v-deep .van-sidebar-item--select::before{
	position: inherit;
}


.venueSection__venuesList__HW7SP {
    padding:4vw 4vw 4vw 0;
}

.venueSection__venuesList__HW7SP .venueSection__venuesItem__yAxzE {
  
    background-size: 100% 100%;

    border-radius: 3vw;
    box-shadow: 0 1vw 2vw 0 rgb(53 120 246 / 10%);
    overflow: hidden;
    position: relative;
    width: 100%;
    will-change: filter;
	margin-bottom: 2.5vw;
}
.index__venueCardItem__ARiYA .index__venueCard__jhtMq .index__bgImg__LzUmU {
    width: 100%;
	min-height: 15vh;
    
}


.index__venueCardItem__ARiYA .index__venueCard__jhtMq .index__venueName__Uucy0 {
        color: #4c5162;
        
        font-size: 5vw;
        font-style: normal !important;
        font-weight: 550;
        left: 5vw;
        position: absolute;
        text-align: center;
        text-align: left;
        top: 14vw;

}









</style>
