<template>
  <div class="bg-container page" style="background: url('img/login/login-bg.png');background-position: 50%;background-repeat: no-repeat;background-size: 100% 100%;">

    <div class="bg-wrapper">
      <div class="login">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#788ebb" @click="back()" />
          </template>
          <template #right>
            <div class="language" @click="$router.push('/language')">
              <img :src="require('../images/language/' + lang + '.png')" />
            </div>
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img
                class="logo-img"
                :src="
                  this.$store.getters.getBaseInfo.ico !== undefined
                    ? this.$store.getters.getBaseInfo.ico
                    : '/img/null.png'
                "
              />
            </div>
          </div>
          <!--div class="title">{{ $t("auth.login") }}</div-->
          <div class="loginForm">
			  
            <van-field
              v-model="username"
              clearable
              input-align="left"
              class="input"
			   left-icon="yonghu"
              :placeholder="$t('auth.username_place')"
			  
            >
			<van-icon
			  name="img/login/login_username.png" 
			   slot="left-icon"
			/>
			 </van-field>
			
			
			
			
            <van-field
              v-model="password"
              :type="passwordType"
              input-align="left"
              class="input"
              :placeholder="$t('auth.pwd_place')"
            >
				<template slot="left-icon">
				  <van-icon
				    name="img/login/login_userpassword.png"
				  />
				</template>
			
              
            </van-field>
			
			
			
			
			
			
            <div class="reset-text" @click="toService()">
              <span>{{$t("auth.forgetpwd")}}?</span>
            </div>
            
            <van-button
              class="login-btn"
              type="primary"
              size="normal"
              @click="doLogin()"
              >{{$t("auth.login")}}</van-button
            >
			
			<van-button
			  class="login-btn register-btn"
			  type="primary"
			  size="normal"
			  @click="toRegister()"
			  >{{$t("auth.no_account")}}</van-button
			>

			
			
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang: 'en_us',
      password: this.inputValue,
      passwordType: "password",
    };
  },
  mounted(){
    // localStorage.setItem("lang", 'ms_my');
    this.lang = localStorage.getItem("lang") || 'en_us';
  },
  methods: {
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    toRegister() {
      this.$router.push("Register");
    },
	toService(){
	  if(this.$store.getters.getBaseInfo.iskefu == 1){
	    this.$router.push("ServiceOnline");
	  }else {
	    this.$toast.fail(this.$t("setting.forbid"));
	  }
	},
    doLogin() {
   		
   		console.log(this.lang);
   	
         if (
           this.username === "" ||
           this.username === null ||
           this.username === undefined
         ) {
           this.$toast(this.$t("auth.username_place"));
           return false;
         }
         if (
           this.password === "" ||
           this.password === null ||
           this.password === undefined
         ) {
           this.$toast(this.$t("auth.pwd_place"));
           return false;
         }
         this.$http({
           url: "member_login",
           method: "post",
           data: { 
             username: this.username, 
             password: this.password,
             lang: this.lang
           },
         }).then((res) => {
           if (res.code === 200) {
             this.$toast.success(res.msg);
             localStorage.setItem("token", res.data.id);
             this.$router.push("Mine");
           } else {
             this.$toast(res.msg);
           }
         });
       },
  
  
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
  height: 100%;
}
.bg-container{
	background: url('');
}

.bg-container .bg-wrapper .login .nav-bar {
  background: 0 0;
}

.language{
		position: absolute;
		    top: 2vw;
		    right: 2vw;
		height: 80px;
		img{
			height: 100%;
		}
		
		
	}

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container {
  margin: 10vw auto;
  width: 45%;
}
.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.login .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.login .wrapper .loginForm {
  padding: 0 45px;
  
}
.login .wrapper .loginForm .input {
  padding: 1vw 4vw;
  margin-top: 25px;
  border-radius: 50px;
  text-align: center;
  line-height: 65px;
  font-size: 30px;
  color: #4e4e4e;
 
}
::v-deep .van-field__control{
	margin-left: 1vw;
	    padding: 0 2vw;
}
::v-deep input:-internal-autofill-selected{
	
	background-color: transparent !important;
	    border-radius: 6.667vw;
		
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.login .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login .wrapper .loginForm .reset-text span {
  color: #718fbd;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.login .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .wrapper .loginForm .register-text span {
  color: #718fbd;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.login .wrapper .loginForm .active {
}
.login .wrapper .loginForm .login-btn {
  margin-top: 20px;
  width: 100%;
  height: 80px;
  border-radius: 50px;
  color: #fff;
   background-image: url('../images/user/login_otp_btn.png');
       background-size: cover;
       background-color: transparent !important;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
.register-btn{
	opacity: 0.7;
}


</style>
