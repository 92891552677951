<template>
<div class="convention-hall page">
  <van-nav-bar
      class="nav-bar"
      :title="$t('reservation.hall')"
  />
  <div class="convention-item" v-show="showlist !== 0">
    <div class="left">
      <van-sidebar @change="onChange" v-model="activeKey">
        <van-sidebar-item :title="'img/home/qb.png'" />
        <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key" :title="v.ico" />
      </van-sidebar>
    </div>
    <div class="right">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" disabled>
        <van-grid :column-num="1">
          <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <div  class="index__venueName__Uucy0">
				<i >{{v.name}}</i>
				</div>
          </van-grid-item>
        </van-grid>
      </van-pull-refresh>
    </div>
  </div>
  <div  class="hide-area" v-show="showlist == 0">
	  {{$t("setting.more_service")}}
  </div>
</div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      gameitem: [{},{},{},{}],
      lotteryitem: [{},{},{},{}],
      isLoading: false,
      activeKey: 0,
	  showlist:0,
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }
    },
	getUserInfo(){
	  this.$http({
	    method: 'get',
	    url: 'user_info'
	  }).then(res=>{
	    if(res.code === 200){
	       
			this.showlist=1;
	        if(this.userInfo.status !== 1){	         
	          localStorage.clear()
	          this.$router.push({path:'/Login'})
	        }
	    }
	  })
	},
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
       

      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data:{class:index},
        url: 'lottery_list'
      }).then(res=>{
        this.gameitem = res.data;
		 
      })
    },
    getLotteryItem(){
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res=>{
        this.lotteryitem = res.data;
		console.log(res.data);
      })
    }
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
	
	if(localStorage.getItem('token')){
		
	    this.getUserInfo();
	}
  }
};
</script>

<style lang='less' scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: #fb788b;
  height: 100px;

}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 160px;
}
.van-sidebar-item--select::before {

  width: 0;
  height: 0;
}
.hide-area{
	margin: 0 auto;
	text-align: center;
	line-height: 140px;
	color: #000000;
	font-family: PingFangSC;
	font-size: 4.8vw;
}
.van-sidebar-item--select {
  color: #7e5678;
  font-size: 35px;
      background-color: #eaf4ff !important;
  text-align: center;
}
/deep/ .van-sidebar-item__text{
  width: 140px;
  margin-left: -25px;
}
.van-sidebar-item{
  font-size: 30px;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
}
.van-sidebar-item img{
	width: 100%;
}
.van-sidebar-item--select, .van-sidebar-item--select:active {
  background-color: #f2f2f5;;
}
.convention-item{
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}
.convention-hall{
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
.convention-item .left{
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right{
  height: 100%;
  flex: 1;
  background-color: #eaf4ff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right .list-wrapper{
  padding: 20px 20px;
  min-height: 800px;
}
.convention-item .right .list-wrapper .game_item_img{
  width: 100%;
}
.convention-item .right .list-wrapper span{
  margin-top: 10px;
  font-size: 30px;
  color: #000;
}
.convention-item .right .list-wrapper span:last-child{
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}
.van-grid-item {
  padding: 10px;

}
.van-grid-item__content{
	position: relative;
	    background-color: transparent;
		padding: 0;
}
::v-deep .van-grid-item__content--center {
  border-radius: 15px;
}



::v-deep .van-image__img{
  border-radius: 40px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

 .index__venueName__Uucy0 {
    color: #4c5162;
    font-size: 5vw;
    font-style: normal !important;
    font-weight: 550;
    left: 5vw;
    position: absolute;
    text-align: center;
    text-align: left;
    top: 14vw;
}




</style>
