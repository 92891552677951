<template>
  <div class="container page">
      <van-nav-bar :title="$t('setting.setting')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="items">
        <div class="item van-hairline--bottom" @click="toInfomation()">
          <div class="left">{{$t("setting.base_setting")}}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toLoginPassword()">
          <div class="left">{{$t("setting.login_pwd")}}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toPayPassword()">
          <div class="left">{{$t("setting.finance_pwd")}}</div>
          <div class="right">
            <span class="desc">{{this.userInfo.paypassword}}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="item van-hairline--bottom" @click="toLanguage()">
          <div class="left">{{$t("setting.language")}}</div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    toPayPassword(){
      if(this.userInfo.paypassword !== this.$t("setting.no_setting")){
        this.$toast(this.$t("withdraw.with_service"));
      }else {
        this.$router.push({path:'/SetPayPassword'});
      }
    },
    toLoginPassword(){
      this.$router.push({path:'/SetLoginPassword'});
    },

    toLanguage(){
      this.$router.push({
        name:'Language',
        params: {
          "type": "setting"
        }
      });
    },
    toInfomation(){
      this.$router.push({path:'/Infomation'});
    },
    loginout(){
        localStorage.clear()
        this.$router.push({path:'/Mine'});
    },
    toServicePage(){
      this.$router.push("ServicePage");
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          if(res.data.paypassword){
            this.userInfo.paypassword = this.$t("setting.y_setting");
          }else {
            this.userInfo.paypassword = this.$t("setting.no_setting");
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .items{
  background-color: #fff;
  font-size: 30px;
  color: #000;
  padding: 0 25px;
}
.container .items .item{
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}
.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .sign-out{
  margin: 500px 40px 0;
  height: 90px;
  line-height: 90px;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: #fb788b;
}
.container  .item .desc{
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.container .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
