

<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#7e5678"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/home-choose.png' : '/img/footer/home.png'"
          :alt="$t('foorter.index')"
          v-show="show !== 0"
        />
        <img
          :src="props.active ? '/img/footer/home-choose.png' : '/img/footer/home.png'"
          :alt="$t('foorter.index')"
          :class="$t('foorter.index')"
          style="height: 4rem"
          v-show="show === 0"
        />
      </template>
    </van-tabbar-item>
    <!-- 游戏 -->
    <van-tabbar-item to="/Game">
      <span>{{ $t("foorter.game") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/cp-choose.png' : '/img/footer/cp.png'"
          :alt="$t('foorter.game')"
          v-show="show !== 1"
        />
        <img
          :src="props.active ? '/img/footer/cp-choose.png' : '/img/footer/cp.png'"
          :alt="$t('foorter.game')"
          :class="$t('foorter.game')"

          v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
   
    <!-- 客服 -->
    <van-tabbar-item to="/ServiceOnline">
      <span>{{ $t("foorter.service") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/kefu-choose.png' : '/img/footer/kefu.png'"
          :alt="$t('foorter.service')"
          v-show="show !== 2"
        />
        <img
          :src="props.active ? '/img/footer/kefu-choose.png' : '/img/footer/kefu.png'"
          :alt="$t('foorter.service')"
          :class="$t('foorter.video')"

          v-show="show === 2"
        />
      </template>
    </van-tabbar-item>
    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>{{ $t("foorter.my") }}</span>
      <template #icon="props">
        <img
          :src="props.active ? '/img/footer/my-choose.png' : '/img/footer/my.png'"
          :alt="$t('foorter.my')"
          v-show="show !== 3"
        />
        <img
          :src="props.active ? '/img/footer/my-choose.png' : '/img/footer/my.png'"
          :alt="$t('foorter.my')"
          :class="$t('foorter.my')"
     
          v-show="show === 3"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>


<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "ServiceOnline") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 3;
        this.show = true;
      }else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "ServiceOnline") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 3;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;

@tabbar-height: 110px;
@tabbar-img: 75px;
.van-tabbar {
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}


.van-tabbar-item {
  font-size: 26px;
}
.tui {
  width: 4rem;
  height: 4rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}

::v-deep .van-tabbar-item--active {
    color: #1989fa;
       background: none;
}







.style__footerTabs__jp4g2 {
    display: flex;
    
    margin: 1vw auto 0;
	padding:1.2vw 3vw 0;
}

.style__footerTabItem__IZEeH {
    align-items: center;
    bottom: 0.8vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.style__staticActiveIcon__p2ggA, .style__staticInActiveIcon__Pv10O {
    height: 10vw;
    width: 10vw;
}
.style__footerTabItem__IZEeH span {
    color: #303442;
 
    font-size: 2.5vw;
}

::v-deep.van-tabbar{
    background: linear-gradient(180deg,#f7fbff,#eaf4ff);
    border-radius: 6vw 6vw 0 0;
    bottom: 0;
    box-shadow: inset 0 0 0.08rem 0 #fff;
    filter: drop-shadow(0 1vw 1vw rgba(15,91,206,.15));

    
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 800;
	    padding: 0 0vh 0.4vh;
}
::v-deep.van-tabbar .van-tabbar-item__text span{
	padding: 0.3vh 0vh 0vh;
	font-size: 3.5vw;
}


</style>
