<template>
  <div class="mine page">
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" :loading-text='this.$t("reservation.refresh")' disabled @refresh="onRefresh">
         <div class="header">
           <van-nav-bar class="nav-bar">
             <template #right>
               <van-icon name="img/mine/top_seting.png" @click="showSetting()" />
				 <van-icon name="img/home/kf.png" @click="toService()" />
			 </template>
			
           </van-nav-bar>

           <div class="user-wrapper" @click="doLogin()">
             <van-image round class="user_img" :src="this.userInfo.header_img">
               <template v-slot:loading>
                 <van-loading type="spinner"/>
               </template>
             </van-image>
             <div class="login-content">
               <p class="login-btn" >{{this.userInfo.username}}</p>

			 
               <!--p class="login-label">{{this.userInfo.ip}}</p-->
             </div>		
			   <van-icon name="img/mine/account_arrow.png" />
           </div>
         </div>
         <div class="content">
        
            <div v-if="this.userInfo.money" class="wallet">
                
                <div class="part-2">
					<div class="zzc">
						<span class="font-28 font-gray">{{$t("my.total_assets")}}</span>
						<div class="refresh-btn" @click="refresh()">
							<van-icon name="img/mine/account_icon_refresh.png" />
						</div>
					</div>
                  <p class="balance van-ellipsis">{{this.userInfo.money}}</p>
                  
                </div>
				
            </div>
            <div :style="{ marginTop: menu_top +'px'}" class="menu" >
				
				
				<div class="menu-item" @click="$router.push({path:'/Recharge'});">
				  <van-image class="menu-item-icon" src="img/home/feature_moneysave.png">
				    <template v-slot:loading>
				      <van-loading type="spinner"/>
				    </template>
				  </van-image>
				  <span class="menu-item-label" >{{$t("my.recharge")}}</span>
				</div>
				<div class="menu-item" @click="doWithdrawal()">
				  <van-image class="menu-item-icon" src="img/home/feature_moneytransfer.png">
				    <template v-slot:loading>
				      <van-loading type="spinner"/>
				    </template>
				  </van-image>
				  <span class="menu-item-label" >{{$t("my.withdraw")}}</span>
				</div>
				
				<div class="menu-item"  @click="$router.push({path:'/Personalreport'});">
				  <van-image class="menu-item-icon" src="img/home/account_treasury.png">
				    <template v-slot:loading>
				      <van-loading type="spinner"/>
				    </template>
				  </van-image>
				  <span class="menu-item-label" >{{$t("my.account_detail")}}</span>
				</div>
				
				<div class="menu-item" @click="exit()" >
				  <van-image class="menu-item-icon" src="img/home/feature_vip.png">
				    <template v-slot:loading>
				      <van-loading type="spinner"/>
				    </template>
				  </van-image>
				  <span class="menu-item-label" >{{$t("my.my_statement")}}</span>
				</div>
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				<div class="menu-item" @click="$router.push({path:'/GameRecord'});">
				  <van-image class="menu-item-icon" src="img/mine/account_jilu.png">
				    <template v-slot:loading>
				      <van-loading type="spinner"/>
				    </template>
				  </van-image>
				  <span class="menu-item-label" >{{$t("my.task_record")}}</span>
				</div>
				
				<div class="menu-item" @click="$router.push({path:'/Infomation'});">
				  <van-image class="menu-item-icon" src="img/mine/infomation.png">
				    <template v-slot:loading>
				      <van-loading type="spinner"/>
				    </template>
				  </van-image>
				  <span class="menu-item-label" >{{$t("my.personal_center")}}</span>
				</div>
				
			  
			  
              <div class="menu-item" @click="$router.push({path:'/Language'});">
                <van-image class="menu-item-icon" src="img/mine/language.png">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("setting.language")}}</span>
              </div>
              
             
              <div class="menu-item" @click="toNotice()">
                <van-image class="menu-item-icon" src="img/mine/account_hist.png">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >{{$t("my.information_announcement")}}</span>
              </div>
              
            </div>
         </div>
		 
		<div class='signout'>
			<van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{$t("setting.logout")}}</van-button>
				
		</div>
      </van-pull-refresh>
    </div>
	
	      
  </div>

</template>

<script>
export default {
  data() {
    return {
      userInfo:{
      },
      menu_top:40,
      isLoading: false,
    };
  },
  methods: {
    refresh(){
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
          this.$toast(this.$t("reservation.refresh"));
        }else{
          this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    exit(){
      this.$toast(this.$t("my.finish_task"));
    },
    showSetting() {
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Setting'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    toNotice(){
      if(localStorage.getItem('token')){
        this.$router.push({path:'/Notice'})
      }else{
        this.$router.push({path:'/Login'})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
            this.getUserInfo();
            this.$toast(this.$t("reservation.refresh"));
        }else{
            this.$router.push({path:'/Login'})
        }
      }, 500);
    },
    doLogin(){
        if(localStorage.getItem('token')){
            this.$router.push({path:'/Infomation'});
        }else {
            this.$router.push({path:'/Login'})
        }
    },
    doPay(){
      this.$router.push({
        name:'Recharge',
        params:{
          'balance':this.userInfo.money
        }
      })
    },
    doWithdrawal(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
          if(res.data.is_bank){
            this.$router.push("withdraw");
          }else {
            this.$router.push("Setbank");
            this.$toast.fail(this.$t("setting.set_bank"));
          }
      })
    },
    toService(){
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
	loginout(){
	    localStorage.clear()
	    this.$router.push({path:'/Login'});
	},
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
			console.log(res.data);
			
			
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast(this.$t("video.account_out"));
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
	 
		localStorage.setItem("lang", this.$i18n.locale);
		
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-image:url('../../../public/img/mine/mine_backgrou.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
}
.mine{
  position: relative;
  bottom: 10px;
}
.mine .wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.nav-bar{
  background: transparent !important;
}
.mine .header{
  
 
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
  margin-left: 20px;
}
.mine .header .user-wrapper{
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}
.mine .user_img{
  height: 100px;
  width: 100px;
}
::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}
::v-deep .van-image__error-icon {
  font-size: 70px;
}
.mine  .header .user-wrapper .login-content{
  flex: 1;
  margin-left: 20px;
}
.mine  .header .user-wrapper .login-content .login-btn{
  display: inline-block;
  font-size: 40px;
  line-height: 8px;
     color: #303442;
     font-weight: 600;
}
.mine .header .user-wrapper .login-content .login-label{
  margin-top: -13px;
  font-size: 28px;
  color: #545a78;
}
.mine .page-bg{
  height: 500px;
  background: linear-gradient(
      90deg,#7e5678,#e6c3a1);
}
.mine .content{
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;

}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .finance .line{
  width: 3px;
  height: 40px;
  background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mine .wrapper .content .finance .finance-item .text{
  margin-left: 30px;
  font-size: 30px;
  color: #000;
  font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon{
  font-size: 50px;
}
.mine .wrapper .content .menu{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label{
  font-size: 30px;
  color: #868686;
  font-weight: 500;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon{
  margin: 25px;
  width: 80px;
  height: 80px;
  -o-object-fit: contain;
  object-fit: contain;
}
.mine .wrapper .content .wallet{
  margin-top: 30px;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
     width: 100%;
}
.mine .wrapper .content .wallet .part-2{
	    width: 100%;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
}

.mine .wrapper .content .wallet .part-1{
  display: flex;
  align-items: center;
  height: 100px;
}
.mine .wrapper .content .wallet .font-primary-color{
  color: #000;
}
.font-gray {
  color: #868686;
}
.mine .wrapper .content .wallet .part-2 .zzc {
  display: flex;
  align-items: center;
 
}
.mine .wrapper .content .wallet .part-2 .balance{

  font-size: 40px;
  color: #303442;
  font-weight: 700;
  margin: 0px  0 0 0;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn{
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 30px;
  border-radius: 50%;
}

.userCard__walletContent__i8o3v {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.userCard__notLoginBox__vaz0Z h3 {
    color: #51587D;
    font-family: PingFangSC;
    font-size: 3.8vw;
    letter-spacing: -0.35px;
    line-height: 3vh;
}
.userCard__notLoginBox__vaz0Z p {
    color: #6c7ba8;
	font-size: 3vw;
	margin-top: 0.5vw;

}

.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV:not(:last-of-type) {
    margin-right: 0.2vw;
	    padding-right: 3vw;
}
.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV .userCard__icon__frlnp {
        height: 11vw;
        position: relative;
        width: 11vw;
}
.userCard__walletContent__i8o3v .userCard__walletItem__XFZPV p {
    color: #51587D;
        display: block;
        font-size: 0.8vw;
        margin-top: -0.8vh;
    
        text-align: center;
        position: relative;
}
.signout{
	text-align: center;
}
.sign-out{
  margin: 10px 0px 0;
  height: 90px;
  line-height: 90px;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  width: 90%;
  background: #fb788b;
}


</style>
